import React, { useState } from 'react';
import './Contact.css';
// import axios from 'axios';
import emailjs from '@emailjs/browser'; // Importation de la bibliothèque EmailJS

const Contactus = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // État pour le message de confirmation

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  const sendmail = (e) => {

    // Configuration de l'envoi de l'email avec EmailJS
    emailjs.send(
      'service_bpfe5k7', // Remplacez par votre Service ID
      'template_63fmi0n', // Remplacez par votre Template ID
      {
        email: email,
        to_name: "Belgacem", // Votre adresse email de destination
        subject: subject,
        message: message,
      },
      'wE4DhYnm1BdnfKt0K' // Remplacez par votre User ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSuccessMessage('We have received your message and will get back to you soon!'); // Mettre à jour le message de confirmation
      setEmail('');
      setSubject('');
      setMessage('');
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setError('Error sending email. Please try again.');
    });
  };


  // const sendmail = () => {
  //   axios.defaults.withCredentials = true;
  //   axios.get('https://www.2wglobalservices.com/build/contact.php', { // https://www.2wglobalservices.com/contact.php
  //     email: email,
  //     subject: subject,
  //     message: message
  //   })
  //   .then((res) => {
  //     // alert(res.data); // Afficher la réponse du serveur
  //     if (res.data.message === "Email sent successfully") {
  //       console.log(res.data);
  //       setSuccessMessage('Email sent successfully'); // Mettre à jour le message de confirmation
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setError('Error sending email. Please try again.');
  //     setSuccessMessage(''); // Réinitialiser le message de confirmation
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !subject || !message) {
      setError('All fields are required');
      setSuccessMessage(''); // Réinitialiser le message de confirmation en cas d'erreur
    } else {
      setError('');
      setSuccessMessage(''); // Réinitialiser le message de confirmation avant d'envoyer le mail
      sendmail();
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label>Professional Email :</label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Subject :</label>
            <input
              type="text"
              value={subject}
              onChange={handleSubjectChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Request :</label>
            <textarea
              value={message}
              onChange={handleMessageChange}
              required
            ></textarea>
          </div>
          <button type="submit">Send</button>
          {successMessage && <p className="success-message">{successMessage}   </p>} {/* Afficher le message de confirmation */}
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="info-container">
        <div className="informations">
          <h2>Informations</h2>
          <ul>
            <li>
              <strong>Email :</strong> contact@aroundexpressions.com
            </li>
            <li>
              {/* <strong>Locations :</strong> */}
              <ul style={{ marginTop: '10px' }}>
                <li>
                  <strong>Portugal:</strong> Parish of Santo António, 11, 1070-100 Lisbon
                </li>
                <li>
                  <strong>Tunisia:</strong> Yasmine Tower Building, Office 4.1, Top Floor, Loc A, Tunis
                </li>
              </ul>
            </li>
            {/* <li>
              <strong>Phone Number :</strong> +351 912 549 922
            </li> */}
          </ul>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12771.450546390528!2d10.193558256973624!3d36.845765702849576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd34c633822983%3A0x815ce59e88baf537!2sYasmine%20Tower%2C%20Bd%20de%20la%20Terre%2C%20Tunis%2C%20Tunisia!5e0!3m2!1sen!2sfr!4v1735324630903!5m2!1sen!2sfr"
            width="400"
            height="300"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Location Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
